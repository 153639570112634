<template>
  <page-layout class="home-page">
    <section class="announce">
      <div class="announce-inner">
        <img src="@/assets/picture/alarm.svg" alt="alarm" />
      </div>
    </section>
    <section class="common-banner">
      <div class="banner-inner">
        <div>
          <h2 class="title">数字资产 尽在掌控</h2>
          <p class="subtitle">全球领先的区块链数字资产管理工具</p>
          <div class="home-download">
            <a href="/download">
              <div class="download-btn">立即下载</div>
            </a>
          </div>
        </div>
        <img class="home-banner" src="@/assets/picture/banner.png" alt="" />
      </div>
    </section>
    <section class="feature" id="feature">
      <div class="feature-inner">
        <p class="title">imToken</p>
        <p class="subtitle">
          为全球超过 150 个国家的千万用户提供安全、可信赖的数字资产管理服务
        </p>
        <div class="feature-cards">
          <div class="ant-card feature-item ant-card-bordered">
            <div class="ant-card-body">
              <p class="title">1200 万</p>
              <p class="subtitle">用户量</p>
            </div>
          </div>
          <div class="ant-card feature-item ant-card-bordered">
            <div class="ant-card-body">
              <p class="title">$5000 亿</p>
              <p class="subtitle">转账金额</p>
            </div>
          </div>
          <div class="ant-card feature-item ant-card-bordered">
            <div class="ant-card-body">
              <p class="title">150+</p>
              <p class="subtitle">覆盖国家和地区</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="product">
      <div class="product-inner">
        <div class="flex-row product-wallet">
          <img src="@/assets/picture/wallet.svg" alt="wallet" />
          <div>
            <p class="title">一个钱包，管理多链资产</p>
            <p class="subtitle">
              轻松管理 BTC, ETH, EOS, ATOM, BCH, TRX, LTC, CKB, DOT, KSM, FIL,
              XTZ
            </p>
            <div class="flex-row">
              <div>
                <p class="number">12</p>
                <p class="desc">已支持公链</p>
              </div>
              <div
                class="ant-divider ant-divider-vertical"
                role="separator"
              ></div>
              <div>
                <p class="number">200K+</p>
                <p class="desc">已收录代币</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-row product-imkey">
          <img src="@/assets/picture/imkey.svg" alt="imkey" />
          <div>
            <p class="title">资产安全，悉心守护</p>
            <p class="subtitle">配备 imKey Pro 硬件钱包，离线签名更安全</p>
            <p class="number">¥688</p>
            <div class="flex-row">
              <div>
                <p class="desc">当前售价</p>
              </div>
              <div
                class="ant-divider ant-divider-vertical"
                role="separator"
              ></div>
              <div>
                <a
                  class="desc more-detail link-hover"
                  href="https://imkey.im/?locale=zh-cn"
                  >立即购买</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="flex-row product-defi">
          <div>
            <p class="title">即时透明，坐享收益</p>
            <p class="subtitle">体验去中心化理财，收益实时获取</p>
          </div>
          <div>
            <img src="@/assets/picture/defi.svg" class="defi-img" alt="defi" />
            <div class="ant-card billboard ant-card-bordered">
              <div class="ant-card-body">
                <a
                  href="https://tokenlon.im/imBTC?locale=zh-cn"
                  class="flex-row item"
                >
                  <div>
                    <p class="profit">0.21%</p>
                    <p class="desc">年化收益</p>
                  </div>
                  <div class="flex-row item-right link-hover">
                    <div>
                      <p class="item-title">imBTC</p>
                      <p class="item-subtitle">BTC 持币生息</p>
                    </div>
                    <img
                      src="@/assets/picture/arrow-right.svg"
                      class="chevron-right"
                      alt="chevron"
                    />
                  </div>
                </a>
                <a
                  href="https://app.compound.finance/?utm_source=token.im&locale=zh-cn"
                  class="flex-row item"
                >
                  <div>
                    <p class="profit">3.02%</p>
                    <p class="desc">年化收益</p>
                  </div>
                  <div class="flex-row item-right link-hover">
                    <div>
                      <p class="item-title">Compound</p>
                      <p class="item-subtitle">DAI 存币生息</p>
                    </div>
                    <img
                      src="@/assets/picture/arrow-right.svg"
                      class="chevron-right"
                      alt="chevron"
                    />
                  </div>
                </a>
                <a
                  href="https://token.boston/staking.html"
                  class="flex-row item"
                >
                  <div>
                    <p class="profit">9.74%</p>
                    <p class="desc">年化收益</p>
                  </div>
                  <div class="flex-row item-right link-hover">
                    <div>
                      <p class="item-title">Staking</p>
                      <p class="item-subtitle">ATOM 质押挖矿</p>
                    </div>
                    <img
                      src="@/assets/picture/arrow-right.svg"
                      class="chevron-right"
                      alt="chevron"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="partner">
      <div class="partner-inner">
        <h2 class="title">生态伙伴</h2>
        <div class="partner-list">
          <a
            class="partner-item partner-logo"
            href="https://www.ethereum.org/"
            target="_blank"
          >
            <img
              src="@/assets/picture/partner-ethereum.svg"
              alt="https://www.ethereum.org"
            />
          </a>
          <a
            class="partner-item partner-logo"
            href="https://z.cash/"
            target="_blank"
          >
            <img src="@/assets/picture/partner-zcash.svg" alt="https://z.cash" />
          </a>
          <a
            class="partner-item partner-logo"
            href="https://polkadot.network/"
            target="_blank"
          >
            <img
              src="@/assets/picture/partner-polkdot.svg"
              alt="https://polkadot.network"
            />
          </a>
          <a
            class="partner-item partner-logo"
            href="https://cosmos.network/"
            target="_blank"
          >
            <img
              src="@/assets/picture/partner-cosmos.svg"
              alt="https://cosmos.network"
            />
          </a>
          <a
            class="partner-item partner-logo"
            href="https://entethalliance.org/"
            target="_blank"
          >
            <img
              src="@/assets/picture/partner-eea.svg"
              alt="https://entethalliance.org"
            />
          </a>
          <a
            class="partner-item partner-logo"
            href="https://new.consensys.net/"
            target="_blank"
          >
            <img
              src="@/assets/picture/partner-consensys.svg"
              alt="https://new.consensys.net"
            />
          </a>
          <a
            class="partner-item partner-logo"
            href="https://etherscan.io/"
            target="_blank"
          >
            <img
              src="@/assets/picture/partner-etherscan.svg"
              alt="https://etherscan.io"
            />
          </a>
          <a
            class="partner-item partner-logo"
            href="https://0xproject.com/"
            target="_blank"
          >
            <img src="@/assets/picture/partner-0x.svg" alt="https://0xproject.com" />
          </a>
          <a
            class="partner-item partner-logo"
            href="https://kyber.network/"
            target="_blank"
          >
            <img src="@/assets/picture/partner-kyber.svg" alt="https://kyber.network" />
          </a>
        </div>
      </div>
    </section>
    <section class="contact">
      <div class="contact-inner">
        <p class="title">联系我们</p>
        <div class="flex-row contact-items">
          <div class="contact-item">
            <img src="@/assets/picture/tokenfans.svg" alt="tokenfans" />
            <div>
              <a class="item-title" href="https://imtoken.fans/">官方论坛</a>
              <a class="item-link" href="https://imtoken.fans/"
                >https://imtoken.fans</a
              >
              <p class="item-subtitle">开放的数字钱包用户互助平台</p>
            </div>
          </div>
          <div class="contact-item">
            <img src="@/assets/picture/feedback.svg" alt="feedback" />
            <div>
              <a class="item-title" href="mailto:support@token.im">提交反馈</a>
              <a class="item-link" href="mailto:support@token.im"
                ><span class="__cf_email__">support@token.im</span></a
              >
              <p class="item-subtitle">
                产品使用遇到问题？尝试向用户支持团队寻找答案吧
              </p>
            </div>
          </div>
          <div class="contact-item">
            <img src="@/assets/picture/business.svg" alt="business" />
            <div>
              <a class="item-title" href="mailto:bd@token.im">商务合作</a>
              <a class="item-link" href="mailto:bd@token.im"
                ><span class="__cf_email__">bd@token.im</span></a
              >
              <p class="item-subtitle">媒体及商务合作联系</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="download waist download-footer">
      <div class="content">
        <div>
          <div class="experience_desc">准备好探索 imToken 了吗？</div>
          <a href="/download">
            <div class="download-btn">马上体验</div>
          </a>
        </div>
        <img src="@/assets/picture/app-example.png" class="example" />
      </div>
    </div>
  </page-layout>
</template>

<script>

export default {
  name: "DownLoad",
  components: {
  },
};
</script>
