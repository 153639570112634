<template>
  <div class="header-style">
    <header class="common-navbar-container">
      <div class="common-navbar">
        <a href="/">
          <img
            class="navbar-logo"
            src="@/assets/picture/imtokenlogo.svg"
            alt="imToken Logo"
          />
        </a>
        <div class="language-switcher">
          <img class="language-icon" src="@/assets/images/globe.svg" />
          <p class="current-language">简体中文</p>
        </div>
        <div class="wap daohangwap">
          <a
            id="mobile-download"
            href="/download"
            style="margin-right: 20px; display: none"
            class="ant-btn ant-btn-primary"
          >
            <span>立即下载</span>
          </a>

          <img src="@/assets/images/menu.svg" class="on" />
          <img src="@/assets/images/close.svg" class="off" />
        </div>
        <nav id="navbar-links" class="pc">
          <div style="visibility: hidden">
            <a
              class="ant-dropdown-link link-hover ant-dropdown-trigger"
              href="#"
              >Features
              <!-- -->
              <img src="@/assets/picture/arrow-down.svg" />
            </a>
            <a
              class="ant-dropdown-link link-hover ant-dropdown-trigger"
              href="#"
              >Coins
              <!-- -->
              <img src="@/assets/picture/arrow-down.svg" />
            </a>
            <a
              class="ant-dropdown-link link-hover ant-dropdown-trigger"
              href="#"
              >Earn
              <!-- -->
              <img src="@/assets/picture/arrow-down.svg" />
            </a>
          </div>
          <div>
            <a
              class="navbar-link link-hover"
              href="https://token.boston/blog.html"
              >博客</a
            >
            <a
              class="navbar-link link-hover"
              href="https://support.token.im/hc/zh-cn"
              >帮助中心</a
            >
            <a href="/download" class="ant-btn ant-btn-primary">
              <span>立即下载</span>
            </a>
          </div>
        </nav>
      </div>
      <div id="space"></div>
      <div class="mobile-language-popover-modal pc">
        <div class="content">
          <p>
            <span><a href="index_en.html">English</a></span>
          </p>
          <p>
            <span><a href="index.html">简体中文</a></span>
          </p>
          <p>
            <span><a href="index_cnf.html">繁体中文</a></span>
          </p>
          <p>
            <span><a href="index_jp.html">日本語</a></span>
          </p>
          <p>
            <span><a href="index_sk.html">한국어</a></span>
          </p>
        </div>
      </div>
      <div class="mobile-menu wap">
        <div class="flex-column button-groups">
          <a
            href="/download"
            class="ant-btn ant-btn-primary ant-btn-block"
          >
            <span>立即下载</span>
          </a>
          <a
            href="https://token.boston/security"
            class="ant-btn light-btn ant-btn-primary ant-btn-block"
          >
            <span>安全中心</span>
          </a>
          <a
            href="https://support.token.im/hc/zh"
            class="ant-btn light-btn ant-btn-primary ant-btn-block"
          >
            <span>帮助中心</span>
          </a>
          <a
            href="https://token.boston/blog"
            class="ant-btn light-btn ant-btn-primary ant-btn-block"
          >
            <span>博 客</span>
          </a>
        </div>

        <ul
          class="ant-menu ant-menu-light ant-menu-root ant-menu-inline"
          role="menu"
          style="width: 256px"
        >
          <li class="ant-menu-submenu ant-menu-submenu-inline" role="menuitem">
            <div
              class="ant-menu-submenu-title"
              aria-expanded="false"
              aria-haspopup="true"
              style="padding-left: 24px"
            >
              <span>功能</span><i class="ant-menu-submenu-arrow"></i>
            </div>
            <ul
              id="feature$Menu"
              class="ant-menu ant-menu-sub ant-menu-hidden ant-menu-inline"
              role="menu"
              style=""
            >
              <li
                class="ant-menu-item"
                role="menuitem"
                style="padding-left: 48px"
              >
                <a href="https://token.boston/wallet">
                  <p class="item-title">多链钱包</p>
                </a>
              </li>
              <li
                class="ant-menu-item"
                role="menuitem"
                style="padding-left: 48px"
              >
                <a
                  href="https://shop42404838.m.youzan.com/v2/showcase/homepage?alias=4aFwAdfHSM"
                >
                  <p class="item-title">硬件钱包</p>
                </a>
              </li>
              <li
                class="ant-menu-item"
                role="menuitem"
                style="padding-left: 48px"
              >
                <a href="https://token.boston/dapp">
                  <p class="item-title">DApp 浏览器</p>
                </a>
              </li>
            </ul>
          </li>
          <li class="ant-menu-submenu ant-menu-submenu-inline" role="menuitem">
            <div
              class="ant-menu-submenu-title"
              aria-expanded="false"
              aria-haspopup="true"
              style="padding-left: 24px"
            >
              <span>公司</span><i class="ant-menu-submenu-arrow"></i>
            </div>
            <ul
              id="company$Menu"
              class="ant-menu ant-menu-sub ant-menu-hidden ant-menu-inline"
              role="menu"
              style=""
            >
              <li
                class="ant-menu-item"
                role="menuitem"
                style="padding-left: 48px"
              >
                <a href="https://token.boston/about">关于我们</a>
              </li>
              <li
                class="ant-menu-item"
                role="menuitem"
                style="padding-left: 48px"
              >
                <a href="https://token.boston/careers">加入我们</a>
              </li>
              <li
                class="ant-menu-item"
                role="menuitem"
                style="padding-left: 48px"
              >
                <a href="https://cn.token.im">中国</a>
              </li>
            </ul>
          </li>
          <li class="ant-menu-submenu ant-menu-submenu-inline" role="menuitem">
            <div
              class="ant-menu-submenu-title"
              aria-expanded="false"
              aria-haspopup="true"
              style="padding-left: 24px"
            >
              <span>资源</span><i class="ant-menu-submenu-arrow"></i>
            </div>
            <ul
              id="resource$Menu"
              class="ant-menu ant-menu-sub ant-menu-hidden ant-menu-inline"
              role="menu"
              style=""
            >
              <li
                class="ant-menu-item"
                role="menuitem"
                style="padding-left: 48px"
              >
                <a href="https://docs.token.im" target="_blank">API</a>
              </li>
              <li
                class="ant-menu-item"
                role="menuitem"
                style="padding-left: 48px"
              >
                <a
                  href="https://github.com/consenlabs/token-profile"
                  target="_blank"
                  >提交代币</a
                >
              </li>
              <li
                class="ant-menu-item"
                role="menuitem"
                style="padding-left: 48px"
              >
                <a href="https://token.boston/brand" target="_blank">品牌</a>
              </li>
            </ul>
          </li>
          <li class="ant-menu-submenu ant-menu-submenu-inline" role="menuitem">
            <div
              class="ant-menu-submenu-title"
              aria-expanded="false"
              aria-haspopup="true"
              style="padding-left: 24px"
            >
              <span>帮助中心</span><i class="ant-menu-submenu-arrow"></i>
            </div>
            <ul
              id="support$Menu"
              class="ant-menu ant-menu-sub ant-menu-hidden ant-menu-inline"
              role="menu"
              style=""
            >
              <li
                class="ant-menu-item"
                role="menuitem"
                style="padding-left: 48px"
              >
                <a href="https://support.token.im/hc/zh" target="_blank"
                  >帮助中心</a
                >
              </li>
              <li
                class="ant-menu-item"
                role="menuitem"
                style="padding-left: 48px"
              >
                <a href="https://token.boston/contact">联系我们</a>
              </li>
              <li
                class="ant-menu-item"
                role="menuitem"
                style="padding-left: 48px"
              >
                <a href="https://token.boston/faq">常见问题</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {},
};
</script>
<style scoped>
.common-navbar .language-switcher {
  display: none;
}
.mobile-language-popover-modal {
  display: none;
}
.common-navbar.on img.navbar-logo {
  display: none;
}
.common-navbar.on .language-switcher.on {
  z-index: 111;
}
.common-navbar.on .language-switcher {
  position: relative;
  z-index: 0;
  display: flex;
  width: 100%;
  justify-content: left;
}
.mobile-language-popover-modal.on.pc {
  display: block !important;
}
</style>