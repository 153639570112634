<template>
  <div class="page-style">
    <layout-header />
    <slot></slot>
    <layout-footer />
  </div>
</template>

<script>
import LayoutHeader from './Header.vue'
import LayoutFooter from './Footer.vue'
export default {
  name: 'PageLayout',
  components: {
    LayoutHeader,
    LayoutFooter
  }
}
</script>
