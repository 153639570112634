<template>
  <div class="header-style">
    <footer class="common-footer">
          <nav class="nav-links">
            <div class="links-wrap pc">
              <div class="links-item">
                <h4 class="links-item-title">功能</h4>
                <a href="https://token.boston/wallet.html">多链钱包</a>
                <a href="https://imkey.im/">硬件钱包</a>
                <a href="https://token.boston/dapp.html">DApp</a>
              </div>
              <div class="links-item">
                <h4 class="links-item-title">资产</h4>
                <a href="https://token.boston/bitcoin-wallet.html">BTC</a> <a href="ethereum-wallet.html">ETH</a> <a
                  href="https://token.boston/cosmos-wallet.html">COSMOS</a> <a
                  href="https://token.boston/eos-wallet.html">EOS</a> <a
                  href="https://token.boston/trx-wallet.html">TRX</a>
                <div style="cursor:pointer"> <span style="font-weight:500">MORE</span> <img alt="down"
                    src="@/assets/picture/down.svg"> </div>
              </div>
              <div class="links-item">
                <h4 class="links-item-title">理财</h4>
                <a href="https://token.boston/staking.html">Staking</a> <a
                  href="https://token.boston/stablecoins.html">稳定币</a>
              </div>
              <div class="links-item">
                <h4 class="links-item-title">公司</h4>
                <a href="https://token.boston/about.html">关于我们</a> <a href="https://token.boston/careers.html">加入我们</a>
              </div>
              <div class="links-item">
                <h4 class="links-item-title">资源</h4>
                <a href="https://docs.token.im/" target="_blank">API</a> <a
                  href="https://github.com/consenlabs/token-profile" target="_blank">提交代币</a> <a
                  href="https://token.boston/brand.html">品牌</a>
              </div>
              <div class="links-item">
                <h4 class="links-item-title">用户支持</h4>
                <a href="https://token.boston/security.html">安全中心</a> <a href="https://support.token.im/hc/en"
                  target="_blank">帮助中心</a> <a href="https://token.boston/faq.html">常见问题</a>
              </div>
            </div>
            <div class="flex-row media-wrap">
              <div>
                <div class="social pc">
                  <a href="https://twitter.com/imTokenOfficial" target="_blank">
                    <img class="social-icon" src="@/assets/picture/twitter.svg" alt="Twitter">
                  </a>
                  <a href="https://medium.com/@imToken" target="_blank">
                    <img class="social-icon" src="@/assets/picture/medium.svg" alt="Medium">
                  </a>
                  <a href="https://imtoken.fans/" target="_blank">
                    <img class="social-icon" src="@/assets/picture/tokenfans.svg" alt="TokenFans">
                  </a>
                  <a href="https://github.com/consenlabs" target="_blank">
                    <img class="social-icon" src="@/assets/picture/github.svg" alt="Github">
                  </a>
                  <a href="https://discord.com/invite/fjX85Ww4Nw" target="_blank">
                    <img class="social-icon" src="@/assets/picture/discord.svg" alt="Github">
                  </a>
                </div>
                <nav class="nav-info">
                  <div class="info">
                    <p class="copyright">©2021 IMTOKEN PTE. LTD. All rights reserved.</p>
                    <a class="pc" href="https://token.boston/tos89a5.html?locale=zh-CN" data-locale="zh-CN"
                      id="term-link" target="_blank">服务条款</a>
                    <div class="language pc">
                      <div class="language"> <img class="language-icon" src="@/assets/picture/globe.svg">
                        <p class="current-language">简体中文</p>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
              <div class="links-item subscribe-item pc">
                <form class="subscribe" action="" method="post" id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form" target="_blank">
                  <input type="email" class="subscribe-input" name="EMAIL" id="mce-EMAIL" placeholder="your@email.com"
                    required="">
                  <img class="subscribe-icon" src="@/assets/picture/subscribe.svg" alt="Subscribe button">
                  <input type="submit" class="subscribe-submit">
                </form>
                <span style="margin-top:10px">及时获取 imToken 最新资讯</span>
              </div>
            </div>
          </nav>
          <div class="friend-link">
            <p class="friend-title">友情链接</p>
            <p class="friend-links"> <a href="https://www.chainnode.com/" target="_blank">链节点</a> <a
                href="https://www.aicoin.cn/" target="_blank">AICoin</a> <a href="https://blockchair.com/?from=imToken"
                target="_blank">BLOCKCHAIR</a> <a href="https://www.tuoluocaijing.cn/" target="_blank">陀螺财经</a> <a
                href="https://mifengcha.com/" target="_blank">蜜蜂查</a> <a href="https://ethfans.org/"
                target="_blank">Ethfans</a> <a href="https://www.chainnews.com/" target="_blank">链闻</a> </p>
          </div>
        </footer>
  </div>
</template>

<script>

export default {
  name: 'Footer',
  components: {
  }
}
</script>
